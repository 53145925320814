import moment from "moment";
import NotFound from "../../../src/assets/images/photo.png";
import styled from "styled-components";
import CustomRow from "../common/_customRow";
import Column from "../common/_customColumn";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const MainContainer = styled.div`
  background-color: #2f2f2f;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  justify-content: space-between;
  /* justify-content: center; */
  padding: 16px;
  min-height: 210px;
  min-width: 11vw;
  gap: 16px;
`;
const StyledButton = styled.button`
  border: 1px solid #47ccd6;
  background-color: #47ccd6;
  border-radius: 8px;
  font-family: "Articulat CF Medium";
  font-size: 16px;
  color: #f6f6f6;
  height: 40px;
  letter-spacing: 0.5px;
  font-weight: bold;
  &:disabled {
    opacity: 0.5;
    background: #5e5e5e;
  }
  &:not(:disabled) {
    &:hover {
      background: #40909a;
      border-color: #40909a;
    }

    &:active {
      background: #47ccd6;
      border-color: #47ccd6;
    }
  }
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;
const TextDescription = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const WorkspaceCard = (props) => {
  let { eachWorkSpace } = props;
  const history = useHistory();

  const pipelineCounts = eachWorkSpace.layout?.node?.length ?? 0;

  const handleWorkSpaceClick = (
    workSpaceId,
    workSpaceName,
    workSpaceIconLink,
  ) => {
    props.handleClickedWorkSpace(workSpaceId, workSpaceName, workSpaceIconLink);
    history.push("/connectorsPlayground");
  };

  return (
    <MainContainer>
      {/* Title and Created at + Icon */}
      <CustomRow>
        <Column
          xs={10.25}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Text
            style={{
              fontSize: "20px",
              color: "#F6F6F6",
              fontFamily: "Articulat CF Medium",
              letterSpacing: "0.5px",
              fontWeight: "bold",
            }}
          >
            {eachWorkSpace.name}
          </Text>
          <Text
            style={{ color: "#777777", fontSize: "12px", lineHeight: "16px" }}
          >{`Created on ${moment
            .unix(eachWorkSpace.createdat)
            .format("DD MMM YYYY")}`}</Text>
        </Column>
        <Column xs={1.5}>
          <img
            src={eachWorkSpace.icon_link ? eachWorkSpace.icon_link : NotFound}
            style={{ height: "44px", width: "44px" }}
          />
        </Column>
      </CustomRow>
      {/* Description Container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Text
          style={{
            color: "#777777",
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "Articulat CF Medium",
            paddingBottom: "2px",
          }}
        >
          Workspace Description
        </Text>
        <TextDescription
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "18.2px",
            letterSpacing: "0.02em",
            color: "#F6F6F6",
            fontFamily: "Articulat CF Medium",
          }}
          className="multiline-ellipsis"
        >
          {eachWorkSpace.description}
        </TextDescription>{" "}
      </div>
      {/* Button Container */}
      {/* <StyledButton
        onClick={() =>
          handleWorkSpaceClick(
            eachWorkSpace.id,
            eachWorkSpace.name,
            eachWorkSpace.icon_link,
          )
        }
      >
        Open Workspace
      </StyledButton> */}
      <StyledButtonPrimary
        onClick={() =>
          handleWorkSpaceClick(
            eachWorkSpace.id,
            eachWorkSpace.name,
            eachWorkSpace.icon_link,
          )
        }
        style={{ height: "40px", borderRadius: "8px" }}
      >
        Open Workspace
      </StyledButtonPrimary>
    </MainContainer>
  );
};

export default WorkspaceCard;
