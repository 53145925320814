import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import ConversionPath from "../../assets/images/cable_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import ElectricCable from "../../assets/images/electrical_services_FILL0_wght400_GRAD0_opsz24 1.svg";
import MergeIcon from "../../assets/images/merge_FILL0_wght400_GRAD0_opsz24 1.svg";
import RecentreIcon from "../../assets/images/recenter_FILL0_wght400_GRAD0_opsz24 1.svg";
import RouteIcon from "../../assets/images/route_FILL0_wght400_GRAD0_opsz24 1.svg";
import TripIcon from "../../assets/images/trip_origin_FILL0_wght400_GRAD0_opsz24 1.svg";
import ZoomIcon from "../../assets/images/zoom_in_map_FILL0_wght400_GRAD0_opsz24 1.svg";
import CheckIcon from "../../assets/images/check_circle_FILL0_wght400_GRAD0_opsz24 1.svg";
import PublishApplicationModal from "./publishApplicationModal";
import GenericModal from "../common/_genericModal";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import TopSnackBar from "../../layouts/Context/_topSnackBar";

import Column from "../common/_customColumn";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";

const MAX_CHARACTERS = 130;

const icons = [
  { src: ConversionPath, alt: "ConversionPath" },
  { src: ElectricCable, alt: "ElectricCable" },
  { src: ZoomIcon, alt: "ZoomIcon" },
  { src: RecentreIcon, alt: "RecentreIcon" },
  { src: TripIcon, alt: "TripIcon" },
  { src: RouteIcon, alt: "RouteIcon" },
  { src: MergeIcon, alt: "MergeIcon" },
];

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

const InputFormContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ModalTextfield = styled.input`
  padding: 10px;
  outline: none;
  background: inherit;
  width: 100%;
  border-radius: 8px;
  border: 2px solid #909090;
  color: #ececec;
  font-size: 16px;
  font-family: Articulat CF Medium;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 14px;
  }
`;

const DescriptionContainer = styled.div`
  position: relative;
`;

const CharacterCount = styled.span`
  position: absolute;
  right: 16px;
  bottom: 16px;
  font-size: 14px;
  color: #b0b0b0;
`;

const ModalTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  outline: none;
  background: inherit;
  resize: none;
  border-radius: 8px;
  border: 2px solid #909090;
  color: #ececec;
  font-size: 16px;
  font-family: Articulat CF Medium;

  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 14px;
  }
`;

const LogoBackground = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 12px;
  background: ${({ selected }) =>
    selected ? "rgba(71, 204, 214, 0.4)" : "#777777"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const StyledIcon = styled.img`
  height: 42px;
  width: 42px;
`;

const CheckMark = styled.img`
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 24px;
  height: 24px;
  display: ${({ selected }) => (selected ? "block" : "none")};
`;

const ModalButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

/**
 * Renders a modal for publishing a connector application.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isModelOpen - Whether the modal is open or not.
 * @param {function} props.setIsModalOpen - Function to toggle the modal open/closed.
 * @param {string} props.buildId - The ID of the build.
 * @param {string} props.applicationId - The ID of the application.
 * @param {string} props.customConnectorIconFile - The name of the custom icon file.
 * @param {string} props.customApplicationName - The name of the previousApplication.
 * @param {string} props.customApplicationVersion - The version of the previousApplication.
 * @param {string} props.customApplicationDescription - The description of the previousApplication.
 * @return {JSX.Element} The rendered PublishConnectorModal component.
 */

const PublishConnectorModal = ({
  isModelOpen,
  setIsModalOpen,
  buildId,
  applicationId,
  customConnectorIconFile,
  customApplicationName,
  customApplicationVersion,
  customApplicationDescription,
}) => {
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);
  const [applicationName, setApplicationName] = useState(
    customApplicationName ? customApplicationName : "",
  );
  const [version, setVersion] = useState(
    customApplicationVersion ? customApplicationVersion : "",
  );
  const [text, setText] = useState(
    customApplicationDescription ? customApplicationDescription : "",
  );
  const [secondModal, setSecondModal] = useState(false);
  const { showMessage } = useContext(TopSnackBar);
  const [publishdisabled, setpublishdisabled] = useState(true);
  const handleChange = (e) => {
    if (e.target.value.length <= MAX_CHARACTERS) {
      setText(e.target.value);
    }
  };

  useEffect(() => {
    if (customConnectorIconFile) {
      setSelectedIconIndex(
        icons.findIndex((icon) => customConnectorIconFile.includes(icon.alt)),
      );
    }
  }, [customConnectorIconFile]);

  const handleIconClick = (index) => {
    setSelectedIconIndex(index);
  };
  const handleSecondModal = () => {
    setSecondModal(true);
  };

  const handlePublish = async () => {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");

    try {
      let stringifyData = {
        workspaceId: parseInt(selectedWorkspaceId),
        applicationName: applicationName,
        applicationVersion: version,
        applicationId: applicationId,
        buildId,
        description: text,
        icon_file: `${icons[selectedIconIndex].alt}.svg`,
      };

      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/publishApplication",
        stringifyData,
      );

      handleSecondModal();
    } catch (err) {
      console.log("Error: publishApplication", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to Publish Application !!!");
      }
    }
  };

  return (
    <>
      <GenericModal
        onClose={() => {}}
        show={isModelOpen}
        title={"Are you sure you want to publish?"}
      >
        <Text
          style={{
            color: "#ECECEC",
            fontSize: "18px",
            lineHeight: "22.4px",
            opacity: "0.7",
            marginBottom: "32px",
          }}
        >
          If you choose to publish this application, everyone in your team will
          be able to access this application and use it in their pipelines.
        </Text>

        <InputFormContainer style={{ marginBottom: "20px" }}>
          <Column xs={12} sm={12} md={12} lg={5.86} xl={5.86} xxl={5.86}>
            <ModalTextfield
              placeholder="Name Your Application"
              onChange={(e) => setApplicationName(e.target.value)}
              value={applicationName}
            />
          </Column>
          <Column xs={12} sm={12} md={12} lg={5.86} xl={5.86} xxl={5.86}>
            <ModalTextfield
              placeholder="Add a Version Number of Your Application"
              onChange={(e) => setVersion(e.target.value)}
              value={version}
            />
          </Column>
          <Column>
            <DescriptionContainer>
              <ModalTextArea
                placeholder="Describe Your Application"
                value={text}
                onChange={handleChange}
                rows={3}
              />
              <CharacterCount>
                {text.length} / {MAX_CHARACTERS}
              </CharacterCount>
            </DescriptionContainer>
          </Column>
        </InputFormContainer>

        <InputFormContainer>
          <Column lg={2.4} xl={2.4} xxl={2.4}>
            <Text
              style={{
                color: "#FAFAFA",
                fontSize: "18px",
                lineHeight: "22.4px",
              }}
            >
              Choose an icon to display
            </Text>
          </Column>
          {icons.map((icon, index) => (
            <Column
              xs={6}
              sm={3}
              md={2}
              lg={1.1}
              xl={1.1}
              xxl={1.1}
              key={index}
            >
              <LogoBackground
                onClick={() => handleIconClick(index)}
                selected={selectedIconIndex === index}
              >
                <StyledIcon
                  src={icon.src}
                  alt={icon.alt}
                  selected={selectedIconIndex === index}
                />
                <CheckMark
                  src={CheckIcon}
                  selected={selectedIconIndex === index}
                />
              </LogoBackground>
            </Column>
          ))}
        </InputFormContainer>

        <ModalButtonContainer>
          <StyledButtonsecondary
            variant="cancel"
            onClick={() => setIsModalOpen(!isModelOpen)}
            style={{ width: "204px" }}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            variant="publish"
            disabled={
              !Boolean(applicationName.trim() && version.trim() && text.trim())
            }
            onClick={() => handlePublish()}
            style={{ width: "204px" }}
          >
            Publish Application
          </StyledButtonPrimary>
        </ModalButtonContainer>
        {secondModal && (
          <PublishApplicationModal
            isModelOpen={secondModal}
            hightLightWord={applicationName}
            connectorName={applicationName}
            version={version}
            description={text}
          />
        )}
      </GenericModal>
    </>
  );
};

export default PublishConnectorModal;
