import React, { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { Container } from "reactstrap";
import styled from "styled-components";
// import ErrorIcon from "../../assets/images/error_snack.png";
// import SuccessIcon from "../"
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";

const StyledTopSnackBar = styled.div`
  position: absolute;
  z-index: 1000;
  width: 320px;
  height: 96px;
  left: 16px;
  bottom: 16px;
  border-radius: 8px;
  background: ${(props) => (props.type === "success" ? "green" : "#EA3A3A29")};
  border: 1.5px solid var(--Danger, #ea3a3a);
  box-shadow: 0px 0px 32px 4px #0000003d;
`;
const MessageTitle = styled.div`
  font-family: "Normal";
  font-size: 18px;
  font-weight: 600;
  color: #f6f6f6;
`;
const Message = styled.div`
  font-family: "Normal";
  font-size: 12px;
  font-weight: 500;
  color: #f6f6f6;
`;

const TopSnackBar = (props) => {
  const { messageTitle, messageContent, showMessage, messageType } =
    useContext(SnackbarContext);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (messageTitle || messageContent) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
        showMessage("", "", "");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [messageTitle, messageContent, showMessage]);

  return (
    <StyledTopSnackBar
      type={messageType}
      style={{
        display: open ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container fluid>
        <CustomRow>
          <Column xs={12}>
            <MessageTitle>{messageTitle}</MessageTitle>
            <Message>{messageContent}</Message>
          </Column>
        </CustomRow>
      </Container>
    </StyledTopSnackBar>
  );
};

export default TopSnackBar;
