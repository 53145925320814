import React, { useState } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory
import styled from "styled-components";
import Column from "../../../components/common/_customColumn";
import GenericModal from "../../../components/common/_genericModal";
import CreateWorkspaceModal from "./CreateWorkspaceModal"; // Assuming you have this modal component
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";

const StyledRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`;

const Button = styled.div`
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ButtonText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #f6f6f6;
`;

const DescriptionText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 500;
  line-height: 22.4px;
  text-align: left;
  opacity: 0.7;
  color: #ececec;
`;

const WorkspaceStatusModal = ({
  show,
  onClose,
  apiResponseStatus,
  handleClickedWorkSpace,
  workSpaces,
}) => {
  const isWorkspaceCreated = apiResponseStatus === 201;
  const history = useHistory();
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
    useState(false);

  const goToWorkspaces = (workSpaceId, workSpaceName, workSpaceIconLink) => {
    handleClickedWorkSpace(workSpaceId, workSpaceName, workSpaceIconLink);
    history.push("/connectorsPlayground");
  };

  const handleTryAgain = () => {
    setShowCreateWorkspaceModal(true);
    onClose(); // Close the current modal
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      {isWorkspaceCreated ? (
        <GenericModal
          show={show}
          onClose={onClose}
          title="Workspace Creation Successful"
        >
          <StyledRow>
            <Column xs={12}>
              <DescriptionText>
                A new workspace has been successfully created. You can now start
                using the workspace, and adding connectors to your pipeline.
              </DescriptionText>
            </Column>
          </StyledRow>
          <StyledRow
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
              paddingTop: "90px",
            }}
          >
            <Column xs={3.8}>
              {/* <Button
                style={{ border: "1px solid #ECECEC" }}
                onClick={() => {
                  goToWorkspaces(
                    workSpaces[0].id,
                    workSpaces[0].name,
                    workSpaces[0].icon_link,
                  );
                }}
              >
                <ButtonText>Go to Workspaces</ButtonText>
              </Button> */}
              <StyledButtonsecondary
                onClick={() => {
                  goToWorkspaces(
                    workSpaces[0].id,
                    workSpaces[0].name,
                    workSpaces[0].icon_link,
                  );
                }}
              >
                Go to Workspaces
              </StyledButtonsecondary>
            </Column>
            <Column xs={3.8}>
              {/* <Button style={{ backgroundColor: "#47ccd6" }} onClick={onClose}>
                <ButtonText>Done</ButtonText>
              </Button> */}
              <StyledButtonPrimary onClick={onClose}>Done</StyledButtonPrimary>
            </Column>
          </StyledRow>
        </GenericModal>
      ) : (
        <GenericModal
          show={show}
          onClose={onClose}
          title="Workspace Creation Unsuccessful"
        >
          <StyledRow>
            <Column xs={12}>
              <DescriptionText>
                There was a problem creating your new workspace due to an error.
                Please try again after sometime.
              </DescriptionText>
            </Column>
          </StyledRow>
          <StyledRow
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
              paddingTop: "90px",
            }}
          >
            <Column xs={3.8}>
              {/* <Button
                style={{ border: "1px solid #ECECEC" }}
                onClick={handleCancel}
              >
                <ButtonText>Cancel</ButtonText>
              </Button> */}
              <StyledButtonsecondary
                onClick={handleCancel}
                style={{ width: "204px" }}
              >
                Cancel
              </StyledButtonsecondary>
            </Column>
            <Column xs={3.8}>
              {/* <Button
                style={{ backgroundColor: "#EA3A3A" }}
                onClick={handleTryAgain}
              >
                <ButtonText>Try Again</ButtonText>
              </Button> */}
              <StyledPrimaryButton2
                onClick={handleTryAgain}
                style={{ width: "204px" }}
              >
                Try Again
              </StyledPrimaryButton2>
            </Column>
          </StyledRow>
        </GenericModal>
      )}
      {showCreateWorkspaceModal && (
        <CreateWorkspaceModal
          show={showCreateWorkspaceModal}
          onClose={() => setShowCreateWorkspaceModal(false)}
        />
      )}
    </>
  );
};

export default WorkspaceStatusModal;
