import styled from "styled-components";
import GenericModal from "../common/_genericModal";
import CustomRow from "../common/_customRow";
import Column from "../common/_customColumn";
import { env } from "../../env";
import axiosAdapter from "../../utils";
import { useContext, useState } from "react";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import GenericSelection from "../common/_dropDownComponent";
import ShowPasswordIcon from "../../assets/images/show_password_icon.svg";
import warning_icon from "../../assets/images/warning_yellow_icon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../common/_buttonNewOne";

const Label = styled.div`
  color: #b0b0b0;
  font-family: "Articulat CF Medium";
  font-size: 14px;
  font-weight: 500;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CreateNewTopicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -10px;
  gap: 8px;
`;

const CreateButton = styled.span`
  font-family: Articulat CF Medium;
  line-height: 20.8px;
  font-size: 16px;
`;

const StyledInput = styled.input`
  padding: 8px;
  outline: none;
  background: #2f2f2f;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #909090;
  color: #ececec;
  font-size: 16px;
  font-family: Articulat CF Medium;
  &::placeholder {
    font-family: Articulat CF Medium;
    color: #b0b0b0;
    font-size: 14px;
  }
`;

const WarningText = styled.div`
  margin-top: 10px;
  font-family: Articulat CF Medium;
  font-size: 15px;
  font-style: italic;
  font-weight: 500;
  line-height: 15.6px;
  display: flex;
  align-items: center;
  color: #edb62e;
`;

const ModalButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

// const StyledButtonPrimary = styled.button`
//   border: none;
//   border-radius: 12px;
//   background-color: inherit;
//   padding: 5px 25px;
//   min-height: 45px;
//   max-width: 200px;
//   min-width: calc(0.8 * 200px);
//   color: #ececec;
//   font-family: Articulat CF Medium;
//   font-size: 16px;
//   font-weight: 600;

//   &:disabled {
//     opacity: 0.5;
//   }
//   &:hover {
//     background-color: #40909a !important;
//   }
//   &:active {
//     background: #47ccd6;
//   }
// `;
const StyledButtonSecondary = styled.button`
  border: 1px solid #ececec;
  border-radius: 12px;
  background-color: inherit;
  padding: 5px 25px;
  min-height: 45px;
  max-width: 200px;
  min-width: calc(0.8 * 200px);
  color: #ececec;
  font-family: Articulat CF Medium;
  font-size: 16px;
  font-weight: 600;

  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: transparent;
    border-color: #47ccd6;
    color: #47ccd6;
  }

  &:active {
    background: #1e565a;
    border-color: #47ccd6;
    color: #47ccd6;
  }
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 15.6px;
  color: #fafafa;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  &::placeholder {
    color: #b0b0b0;
  }
`;

const InputFieldContainer = styled.div`
  padding: 16px 12px;
  width: 100%;
  border-radius: 12px;
  border: 2px solid #b0b0b0;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  margin: 0;
`;

const EditConfigurationModal = (props) => {
  const {
    toggleEditModal,
    isModelOpen,
    configs,
    setConfigs,
    allTopics,
    setAllTopics,
    nodeData,
  } = props;
  const { showMessage } = useContext(SnackbarContext);
  const [newTopicNamesMapper, setNewTopicNamesMapper] = useState({}); // mapper to store the new topicnames entered
  const [newTopicModalMapper, setNewTopicModalMapper] = useState({}); // mapper to store the state of new topic modal
  const [showPassMapper, setShowPassMapper] = useState({}); // mapper to store the state of visibility of password
  const [saveLoading, setSaveLoading] = useState(false);

  // Function to create a new topic and store it if the response is successful
  const createNewTopic = async (index, newTopicName) => {
    try {
      let topicCreationResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "kafkaadmin/createNewTopic",
        JSON.stringify({
          topicName: newTopicName,
        }),
      );
      if (topicCreationResponse.data.status === "success") {
        setAllTopics((prev) => {
          let tempTopics = [...prev, { name: newTopicName }];
          return tempTopics;
        });
        setConfigs((prev) => {
          let tempConfigs = [...prev];
          tempConfigs[index] = {
            ...tempConfigs[index],
            value: { name: newTopicName },
          };
          return tempConfigs;
        });
        setNewTopicNamesMapper((prev) => {
          let tempMap = { ...prev, [index]: null };
          return tempMap;
        });
        setNewTopicModalMapper((prev) => {
          let tempMap = { ...prev, [index]: false };
          return tempMap;
        });
      } else {
        showMessage(topicCreationResponse.data.message);
      }
    } catch (err) {
      console.log("Err createNewTopic", err);
      showMessage("Error in creating new topic");
    }
  };

  const handleUpdateConfig = async () => {
    try {
      setSaveLoading(true);
      let configObj = {};
      for (let item of configs) {
        if (item.type === "topic") {
          if (item.isRequired && (!item.value || !item.value.name)) {
            let error = new Error(
              `Please select or create a topic for the field ${item.label} (${item.direction})`,
            );
            error.code = "INPUT_ERROR";
            throw error;
          }
          configObj[item.name] = item.value.name;
        } else {
          if (item.isRequired && !item.value) {
            let error = new Error(
              `Please enter value for the field '${item.label}'`,
            );
            error.code = "INPUT_ERROR";
            throw error;
          }
          configObj[item.name] = item.value;
        }
      }
      try {
        let stringifiedData = JSON.stringify({
          id: nodeData.configId,
          config: configObj,
        });
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/updateConnectorConfigs",
          stringifiedData,
        );
      } catch (err) {
        err.code = "CONFIG_ERROR";
        throw err;
      }
      window.location.reload();
      setSaveLoading(false);
    } catch (err) {
      if (err.code === "INPUT_ERROR") {
        showMessage(err.message);
      } else if (err.code === "CONFIG_ERROR") {
        showMessage("Failed to set the configuration of this connector");
      } else {
        showMessage("Failed to configure the selected connector");
      }
      setSaveLoading(false);
      console.log("handelUpdateConfig", err);
    }
  };
  return (
    <GenericModal
      title="Edit Configured Fields"
      show={isModelOpen}
      onClose={toggleEditModal}
    >
      <CustomRow>
        <Column style={{ marginBottom: "16px" }}>
          <Text
            style={{
              opacity: "0.7",
              color: "#ECECEC",
              fontSize: "18px",
              lineHeight: "22.4px",
            }}
          >
            Please add new configurations to override the previously added
            configuration. Please note, once you hit save, previously added
            configurations will be lost.
          </Text>
        </Column>
        {configs.map((field, index) => {
          if (field.type === "topic") {
            return (
              <Column style={{ marginBottom: "16px" }} key={index}>
                <InputContainer>
                  <Label>
                    {`${field.label} (${field.direction})`}
                    {field.isRequired && " *"}
                  </Label>
                  <GenericSelection
                    dropdownItems={allTopics}
                    selectedItem={field.value}
                    placeholder={"Select a Topic"}
                    onSelect={(item) => {
                      let tempConfigs = [...configs];
                      tempConfigs[index] = {
                        ...tempConfigs[index],
                        value: item,
                      };
                      setConfigs(tempConfigs);
                    }}
                    nameFunction={(item) => item.name}
                    filterFunction={(arr, text) =>
                      arr.filter((item) =>
                        item.name.toLowerCase().includes(text.toLowerCase()),
                      )
                    }
                    searchPlaceHolder={"Search for Topics"}
                  />
                  <CreateNewTopicContainer>
                    <CreateButton style={{ color: "#777777" }}>
                      Can't Find the Topic?
                    </CreateButton>
                    <CreateButton
                      style={{
                        color: "#CCCCCC",
                        textDecoration: "underline",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setNewTopicModalMapper((prev) => {
                          let tempMap = { ...prev, [index]: true };
                          return tempMap;
                        })
                      }
                    >
                      Create a New Topic
                    </CreateButton>
                  </CreateNewTopicContainer>
                  <GenericModal
                    onClose={() =>
                      setNewTopicModalMapper((prev) => {
                        let tempMap = { ...prev, [index]: false };
                        return tempMap;
                      })
                    }
                    show={newTopicModalMapper[index] || false}
                    title={"Create a New Topic"}
                  >
                    <StyledInput
                      placeholder="Add a New Topic Name"
                      value={newTopicNamesMapper[index] || ""}
                      onChange={(e) =>
                        setNewTopicNamesMapper((prev) => {
                          let tempNewTopicMap = {
                            ...prev,
                            [index]: e.target.value,
                          };
                          return tempNewTopicMap;
                        })
                      }
                    />
                    <WarningText>
                      <img
                        src={warning_icon}
                        alt="warning_icon"
                        style={{ marginRight: "5px" }}
                      />
                      A New Topic with this name will be created and available
                      for use
                    </WarningText>
                    <ModalButtonContainer>
                      <StyledButtonSecondary
                        onClick={() =>
                          setNewTopicModalMapper((prev) => {
                            let tempMap = { ...prev, [index]: false };
                            return tempMap;
                          })
                        }
                      >
                        Cancel
                      </StyledButtonSecondary>
                      <StyledButtonPrimary
                        style={{ backgroundColor: "#47CCD6" }}
                        disabled={!newTopicNamesMapper[index]?.length}
                        onClick={() =>
                          createNewTopic(index, newTopicNamesMapper[index])
                        }
                      >
                        Create Topic
                      </StyledButtonPrimary>
                    </ModalButtonContainer>
                  </GenericModal>
                </InputContainer>
              </Column>
            );
          } else
            return (
              <Column style={{ marginBottom: "16px" }} key={index}>
                <InputContainer key={index}>
                  <Label htmlFor={field.name}>
                    {field.label}
                    {field.isRequired && " *"}
                  </Label>
                  <InputFieldContainer
                    style={
                      field.type === "password" ? { padding: "12px 12px" } : {}
                    }
                  >
                    <InputField
                      type={
                        field.type === "password"
                          ? showPassMapper[index]
                            ? "text"
                            : field.type
                          : field.type
                      }
                      id={field.name}
                      name={field.name}
                      placeholder={field.placeHolder}
                      required={field.isRequired}
                      value={field.value || ""}
                      onChange={(e) => {
                        let tempConfigs = [...configs];
                        tempConfigs[index] = {
                          ...tempConfigs[index],
                          value: e.target.value,
                          ...(field.label === "Title"
                            ? {
                                name: "title",
                              }
                            : {}),
                        };
                        setConfigs(tempConfigs);
                      }}
                      readOnly={field.label === "Title"}
                    />
                    {field.type === "password" && (
                      <img
                        src={ShowPasswordIcon}
                        alt="Show Password"
                        onClick={() => {
                          let tempMap = { ...showPassMapper };
                          tempMap[index] = !tempMap[index];
                          setShowPassMapper(tempMap);
                        }}
                        style={{ cursor: "pointer", marginLeft: "5px" }}
                      />
                    )}
                  </InputFieldContainer>
                </InputContainer>
              </Column>
            );
        })}
        <Column>
          <ModalButtonContainer>
            {/* <StyledButton
              onClick={() => toggleEditModal()}
              disabled={saveLoading}
            >
              Cancel
            </StyledButton> */}
            <StyledButtonsecondary
              onClick={() => toggleEditModal()}
              disabled={saveLoading}
              style={{ width: "204px" }}
            >
              Cancel
            </StyledButtonsecondary>
            {/* <StyledButton
              style={{ backgroundColor: "#47CCD6" }}
              disabled={saveLoading}
              onClick={handleUpdateConfig}
            >
              Save Changes
            </StyledButton> */}
            <StyledButtonPrimary
              disabled={saveLoading}
              onClick={handleUpdateConfig}
              style={{ width: "204px" }}
            >
              Save Changes
            </StyledButtonPrimary>
          </ModalButtonContainer>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

export default EditConfigurationModal;
