import axios from "axios";
import { useEffect, useState } from "react";
import Typography from "../../components/common/_typography";
import { Col, Container, Row } from "reactstrap";
import ProfileCard from "../../components/onBoardingComponents/_profileCard";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import SkeletonLoading from "../../components/common/_skeletonLoading";
import WorkspaceCard from "../../components/onBoardingComponents/_defaultWorkspaceCard";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import styled from "styled-components";
import WorkspaceBlock from "../../components/onBoardingComponents/_workspaceBlocks";
import CustomText from "../../assets/images/This is your workspace.png";
import CreateWorkspaceModal from "../Pipelines/Components/CreateWorkspaceModal";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";

import loadingAnimation from "../../assets/images/Loading Animation.gif";
import TopSnackBar from "../../layouts/Context/_topSnackBar";

const MainText = styled.p`
  font-size: 32px;
  font-family: "Articulat CF Medium";
  line-height: 41.6px;
  color: #f6f6f6;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.multiline-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const StyleButton = styled.button`
  border-radius: 12px;
  border-color: #47ccd6;
  border-width: 1px;
  border-style: solid;
  background: #47ccd6;
  cursor: pointer;
  height: 48px;
  width: 204px;
  &:disabled {
    opacity: 0.5;
    background: #5e5e5e;
  }
  &:not(:disabled) {
    &:hover {
      background: #40909a;
      border-color: #40909a;
    }

    &:active {
      background: #47ccd6;
      border-color: #47ccd6;
    }
  }
`;

const NameDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-bottom: 12px;
  overflow: hidden;
  padding-left: 24px;
  padding-right: 20px;
`;

const ButtonContent = styled.div`
  font-size: 16px;
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;

  &&::-webkit-scrollbar {
    height: 8px;
  }

  &&::-webkit-scrollbar-thumb {
    background: none;
    border-radius: none;
  }

  &&::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
  }
`;

const BlockDiv = styled.div`
  flex: 0 0 25%;
  box-sizing: border-box;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const OnboardingWorkSpaceList = (props) => {
  const { reFetchWorkSpace } = props;
  const { title, showButton, handleRefetchWorkSpace } = props;
  const [workSpaces, setWorkSpaces] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [workSpaceName, setWorkSpaceName] = useState("");
  const [workSpaceDescription, setWorkSpaceDescription] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    fetchWorkSpaces();
    fetchBlogPosts();
  }, [reFetchWorkSpace]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateWorkSpaceModal = () => {
    setIsModalOpen(!isModelOpen);
    setWorkSpaceName("");
    setWorkSpaceDescription("");
    setSelectedIcon("");
  };

  const fetchWorkSpaces = async () => {
    setIsLoading(true);
    let fetchWorkSpacesResponse = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
    );
    //API Call: Validating OTP and Resetting Password.

    const sortedData = fetchWorkSpacesResponse.data.data.sort(
      (a, b) => b.id - a.id,
    );

    setWorkSpaces(sortedData);
    setIsLoading(false);
  };

  const fetchBlogPosts = async () => {
    let fetchBlogPosts = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getAllBlogPostList",
    );
    setBlogPost(fetchBlogPosts.data.data);
  };

  const handleClickedWorkSpace = (
    workSpaceId,
    workSpaceName,
    workSpaceIconLink,
  ) => {
    //Finding selected workspace
    let selectedWorkSpace = workSpaces.find(
      (eachWorkSpace) => eachWorkSpace.id === workSpaceId,
    );

    localStorage.setItem("selectedWorkSpaceName", workSpaceName);
    localStorage.setItem("selectedWorkSpaceId", workSpaceId);
    localStorage.setItem("selectedWorkSpaceIconLink", workSpaceIconLink);

    props.handlingWorkSpace(workSpaceId, selectedWorkSpace.layout);
  };
  return (
    <>
      <TopSnackBar />
      {isLoading ? (
        <ParentContainer>
          <AnimationContainer>
            <ScrollAnimationBar />
            <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
          </AnimationContainer>
        </ParentContainer>
      ) : (
        <>
          <NameDiv>
            <MainText className="multiline-ellipsis">
              Welcome to Condense, {localStorage.getItem("userName")}
            </MainText>

            {/* <StyleButton>
              <ButtonContent onClick={handleCreateWorkSpaceModal}>
                Add new Workspace
              </ButtonContent>
            </StyleButton> */}
            <StyledButtonPrimary
              onClick={handleCreateWorkSpaceModal}
              style={{
                height: "48px",
                width: "204px",
              }}
            >
              Add New Workspace
            </StyledButtonPrimary>
          </NameDiv>
          <CustomRow
            // justifyContent={"normal"}
            alignItems={"stretch"}
            style={{
              gap: "20px",
              marginBottom: "32px",
              marginLeft: "26px",
              marginRight: "24px",
            }}
          >
            {workSpaces.length > 1 &&
              workSpaces.map((eachWorkSpace) => {
                return (
                  <Column xs={12} md={2.9} sm={5.8} lg={2.85}>
                    <WorkspaceCard
                      eachWorkSpace={eachWorkSpace}
                      handleClickedWorkSpace={handleClickedWorkSpace}
                    ></WorkspaceCard>
                  </Column>
                );
              })}
            {workSpaces.length == 1 &&
              workSpaces.map((eachWorkSpace) => {
                return (
                  <>
                    <Column xs={12} md={2.9} sm={5.8} lg={2.85}>
                      <WorkspaceCard
                        eachWorkSpace={eachWorkSpace}
                        handleClickedWorkSpace={handleClickedWorkSpace}
                      ></WorkspaceCard>
                    </Column>
                    <img
                      style={{ margin: "48px 0px 0px 13px" }}
                      height="135px"
                      src={CustomText}
                    ></img>
                  </>
                );
              })}

            {isModelOpen && (
              <CreateWorkspaceModal
                show={isModelOpen}
                onClose={handleCreateWorkSpaceModal}
                handleRefetchWorkSpace={handleRefetchWorkSpace}
              />
            )}
          </CustomRow>

          <MainText
            className="multiline-ellipsis"
            style={{ marginBottom: "24px", paddingLeft: "24px" }}
          >
            Getting Started with Condense
          </MainText>
          <ScrollContainer>
            {blogPost.length > 0 &&
              blogPost.map((eachBlog) => {
                return (
                  <BlockDiv>
                    <WorkspaceBlock eachBlog={eachBlog} />
                  </BlockDiv>
                );
              })}
          </ScrollContainer>
        </>
      )}

      <br />
      <CreateWorkspaceModal
        isWorkspaceCreationModalOpen={isModelOpen}
        onClose={handleCreateWorkSpaceModal}
        handleRefetchWorkSpace={handleRefetchWorkSpace}
        handleCloseModal={handleCloseModal}
        handleClickedWorkSpace={handleClickedWorkSpace}
        workSpaces={workSpaces}
      />
    </>
  );
};
export default OnboardingWorkSpaceList;
