import { useCallback, useContext, useEffect, useState } from "react";
import { ConnectorsContext } from "./WorkSpaceConnectorsMainPage";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import MoreInfoPart from "../../components/ConfigForm/moreInfoPart";
import ConfigForm from "../../components/ConfigForm/newConfigForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import ReactflowForConfigCard from "./ReactflowForConfigCard";
import styled from "styled-components";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import GenericModal from "../../components/common/_genericModal";
import { getNodesAndEdgesWithNewnode } from "./utils/getNodesAndEdges";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import GridBackground from "../../assets/images/grid 1 (1).png";
import ReactFlowNodeCard from "./ReactFlowNodeCard";
import Stepper from "../../pages/WorkSpaceConnectors/Stepper";

const ConnectorConfigBottomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0px;
`;

// const StyledButton = styled.button`
//   border: none;
//   border-radius: 12px;
//   background-color: inherit;
//   padding: 5px 25px;
//   color: #ececec;
//   font-family: "Articulat CF Medium";
//   font-size: 16px;
//   font-weight: 600;
//   max-width: calc(1.2 * 200px);
//   min-width: calc(0.9 * 200px);
//   min-height: 45px;
//   &:disabled {
//     opacity: 0.5;
//   }
//   &:hover {
//     /* background-color: #993a3a !important; */
//   }
//   &:active {
//     /* background: #ea3a3a; */
//   }
// `;

// const StyledButtonPrimary = styled.button`
//   border: none;
//   border-radius: 12px;
//   background-color: inherit;
//   padding: 5px 25px;
//   color: #ececec;
//   font-family: "Articulat CF Medium";
//   font-size: 16px;
//   font-weight: 600;
//   max-width: calc(1.2 * 200px);
//   min-width: calc(0.9 * 200px);
//   min-height: 45px;
//   &:disabled {
//     opacity: 0.5;
//   }
//   &:hover {
//     background-color: #40909a !important;
//   }
//   &:active {
//     background: #47ccd6;
//   }
// `;
// const StyledButtonSecondary = styled.button`
//   border: 1px solid #ececec;
//   border-radius: 12px;
//   background-color: inherit;
//   padding: 5px 25px;
//   color: #ececec;
//   font-family: "Articulat CF Medium";
//   font-size: 16px;
//   font-weight: 600;
//   max-width: calc(1.2 * 200px);
//   min-width: calc(0.9 * 200px);
//   min-height: 45px;
//   &:disabled {
//     opacity: 0.5;
//   }
//   &:hover {
//     background: transparent;
//     border-color: #47ccd6;
//     color: #47ccd6;
//   }

//   &:active {
//     background: #1e565a;
//     border-color: #47ccd6;
//     color: #47ccd6;
//   }
// `;
const ModalDescription = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 18px;
  font-weight: 500;
  line-height: 22.4px;
  color: #ececec;
  opacity: 0.7;
  margin-bottom: 30px;
  display: flex;
`;

const StepperStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fafafa;
  font-family: "Articulat CF Medium";
`;

const ModalButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const PreviewContainer = styled.div`
  background-color: #2f2f2f;
  height: 30%;
  border-radius: 12px;
  background-image: url(${GridBackground});
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const PreviewTitle = styled.div`
  font-family: Articulat CF Medium;
  top: 10;
  font-size: 24px;
  color: #f6f6f6;
  position: absolute;
  top: 24px;
  left: 24px;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const PreviewCard = styled.div``;

const getButtonName = (step, isTestConnection) => {
  switch (step) {
    case 1:
      return isTestConnection ? "Test Connection" : "Configure";
    case 2:
      return "Deploy";
    default:
      return "";
  }
};

const filterValidInterfaces = (arr) => {
  return arr.filter(Boolean);
};

const ConnectorsConfigurationPage = (props) => {
  const {
    workspaceLayout,
    selectedConnectorName,
    selectedConnectorType,
    selectedConnectorId,
    selectedConnectorCategory,
    selectedConnectorDescription,
    customConnectorVersion,
  } = props;
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const { handleBackClick } = useContext(ConnectorsContext);
  // const [curre setCurrentStep] = useState(1);
  // const [animatingLine, setAnimatingLine] = useState(null);

  const [deviceData, setDeviceData] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [isTestConnectionApplicable, setTestConnectionAppicable] =
    useState(false);
  const [isConfigureProcessing, setConfigureProcessing] = useState(false);
  const [configurationId, setConfigurationId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalTitleText, setModalTitleText] = useState("");
  const [previewData, setPreviewData] = useState({});
  const [content, setContent] = useState("");
  const [connectorVersionList, setConnectorVersionList] = useState([]);
  const [selectedConnectorVersion, setSelectedConnectorVersion] = useState(
    customConnectorVersion || "",
  );

  // Function to close the success/fail modal
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Function to handle the right side button of modal based on the current stepper
  const handleModalButton = () => {
    if (currentStep === 1) {
      handleModalClose();
    } else {
      // Successful redirect to playground page
      if (modalTitleText === "Deployment Successful!") {
        history.push({
          pathname: "connectorsPlayground",
          selectedWorkspace: localStorage.getItem("selectedWorkSpaceId"),
        });
      } else {
        handleModalClose();
      }
    }
  };

  const setConnectorConfigsAndTestFunction = async (configArg) => {
    let tempConfigurationId;
    try {
      let stringifiedData = JSON.stringify(configArg);
      let setConfigsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setConnectorConfigsNew",
        stringifiedData,
      );
      tempConfigurationId = setConfigsResponse.data.data.id;
      setConfigurationId(tempConfigurationId);
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Setting Configuration Failed");
      err.code = "CONFIG_ERROR";
      throw err;
    }
    if (isTestConnectionApplicable) {
      try {
        let stringifiedData = JSON.stringify({
          id: tempConfigurationId,
          connectorName: selectedConnectorName,
        });
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/testConnectorConfigsNew",
          stringifiedData,
        );
      } catch (err) {
        setModalOpen(true);
        setModalTitleText("Connection Failed");
        err.code = "TEST_ERROR";
        throw err;
      }
    }
  };

  const deployAndSetWorkspace = async (configId, nodes, edges) => {
    try {
      let stringifiedData = JSON.stringify({
        connectorId: configId,
        isDevice: selectedConnectorCategory === "Telematics Device",
      });
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setTransformDeploymentNew",
        stringifiedData,
      );
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Deployment Failed");
      err.code = "DEPLOY_ERROR";
      throw err;
    }
    try {
      let stringifyData = {
        id: localStorage.getItem("selectedWorkSpaceId"),
        layout: {
          node: nodes,
          edges: edges,
        },
      };
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData,
      );
    } catch (err) {
      setModalOpen(true);
      setModalTitleText("Setting Workspace Failed");
      err.code = "WORKSPACE_ERROR";
      throw err;
    }
  };

  const goBackClickHandler = () => {
    if (currentStep === 1) {
      handleBackClick();
    } else if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  const handleNextClick = async () => {
    // if (currentStep < 3) {
    //   setAnimatingLine(currentStep);
    //   setCurrentStep((prev) => prev + 1);
    // }
    if (currentStep === 1) {
      try {
        setConfigureProcessing(true);
        let configArg,
          configObj = {};
        if (selectedConnectorCategory === "Telematics Device") {
          if (!configs.length) {
            let error = new Error(
              "Please select a device before configuration",
            );
            error.code = "INPUT_ERROR";
            throw error;
          }
          for (let [index, item] of configs.entries()) {
            if (index === 0) continue;
            if (item.type === "topic") {
              if (item.isRequired && (!item.value || !item.value.name)) {
                let error = new Error(
                  `Please select or create a topic for the field ${item.label} (${item.direction})`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              configObj[item.name] = item.value.name;
            } else {
              if (item.isRequired && !item.value) {
                let error = new Error(
                  `Please enter value for the field '${item.label}'`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              configObj[item.name] = item.value;
            }
          }
          configArg = {
            connectorName: configs[0].value,
            type: "Input",
          };
          configArg.config = configObj;
        } else {
          configArg = {
            connectorName: selectedConnectorName,
            type: selectedConnectorType,
          };
          for (let item of configs) {
            if (item.type === "topic") {
              if (item.isRequired && (!item.value || !item.value.name)) {
                let error = new Error(
                  `Please select or create a topic for the field ${item.label} (${item.direction})`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              configObj[item.name] = item.value.name;
            } else {
              if (item.isRequired && !item.value) {
                let error = new Error(
                  `Please enter value for the field '${item.label}'`,
                );
                error.code = "INPUT_ERROR";
                throw error;
              }
              if (item.type === "port") {
                if (
                  !Boolean(
                    parseInt(item.value) >= item.min &&
                      parseInt(item.value) <= item.max,
                  )
                ) {
                  let error = new Error(
                    `Please enter port number between ${item.min} and ${item.max} range`,
                  );
                  error.code = "INPUT_ERROR";
                  throw error;
                }
              }
              configObj[item.name] = item.value;
            }
          }
          configArg.config = configObj;
          if (selectedConnectorType === "Custom Connector") {
            configArg.customConnectorId = selectedConnectorId;
            configArg.customConnectorVersion = selectedConnectorVersion;
          }
        }
        await setConnectorConfigsAndTestFunction(configArg);
        setCurrentStep(2);
        setModalOpen(true);
        setModalTitleText(
          selectedConnectorCategory === "Telematics Device"
            ? "Configuration Successful!"
            : "Connection Successful!",
        );
        setPreviewData((prevData) => {
          let tempInterfaces = filterValidInterfaces(prevData.interfaces);
          return {
            ...prevData,
            interfaces: tempInterfaces,
            firstNode: tempInterfaces.every((obj) => obj.direction !== "input"),
          };
        });
        setConfigureProcessing(false);
      } catch (err) {
        if (err.code === "INPUT_ERROR") {
          showMessage(err.message);
        } else if (err.code === "CONFIG_ERROR") {
          showMessage("Failed to set the configuration of this connector");
        } else if (err.code === "TEST_ERROR") {
          showMessage(
            "Failed during Connection Test of the Connector Configurations",
          );
        } else {
          showMessage("Failed to configure the selected connector");
        }
        setConfigureProcessing(false);
        console.log("err handleNextClick 1", err);
      }
    }

    if (currentStep === 2) {
      if (!workspaceLayout) {
        showMessage("Unable to obtain the data for workspace");
        return;
      }
      setConfigureProcessing(true);
      let { nodes, edges } = getNodesAndEdgesWithNewnode(
        workspaceLayout.node,
        workspaceLayout.edges,
        {
          id: configurationId.toString(),
          type: selectedConnectorType,
          data: {
            ...previewData,
            configId: configurationId,
          },
          targetPosition: "left",
          sourcePosition: "right",
          position: {
            x: null,
            y: null,
          },
        },
      );
      try {
        await deployAndSetWorkspace(configurationId, nodes, edges);
        setConfigureProcessing(false);
        setModalOpen(true);
        setModalTitleText("Deployment Successful!");
      } catch (err) {
        if (err.code === "DEPLOY_ERROR") {
          showMessage("Failed to deploy this connector");
        } else if (err.code === "WORKSPACE_ERROR") {
          showMessage(
            "Failed during Setting of Workspace with the deployed connector",
          );
        } else {
          showMessage(
            "Failed to Deploy and Set the workspace for the selected connector",
          );
        }
        setConfigureProcessing(false);
        console.log("err handleNextClick 2", err);
      }
    }
    props.nextStep();
  };

  const fetchAllTopics = async () => {
    let allTopicsResponse = await axiosAdapter(
      "GET",
      env.REACT_APP_URL + "kafkaadmin/getAllTopics",
    );
    return allTopicsResponse.data;
  };

  const fetchCustomConnectorUserConfigs = useCallback(
    async (customConnectorId, customConnectorVersion) => {
      let stringifiedData = JSON.stringify({
        customConnectorId,
        customConnectorVersion,
      });
      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getCustomConnectorUserConfigs",
        stringifiedData,
      );
      setConfigs(
        fetchConfigResponse.data.data.map((obj) => ({
          value: null,
          ...obj,
        })),
      );
    },
    [],
  );

  // Function to fetch the devices for the manufacturer id
  const fetchDevicesforManfacturer = async () => {
    try {
      let stringifiedData = JSON.stringify({
        manufacturerId: selectedConnectorId,
      });

      let fetchedDevicesforManufacturer = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/getAllTelematicsDevicesForManufacturer",
        stringifiedData,
      );

      setDeviceData(fetchedDevicesforManufacturer.data.data);

      setPreviewData((prev) => ({
        ...prev,
        connectionType: selectedConnectorType,
        category: selectedConnectorCategory,
        name: selectedConnectorName,
        description: selectedConnectorDescription,
        interfaces: [],
      }));

      let allTopics = await fetchAllTopics();

      setAllTopics(allTopics.data.topics);
    } catch (err) {
      console.log("fetchDevicesforManfacturer", err);
      showMessage("Unable to fetch the devices for the selected connector");
      setDeviceData([]);
    }
  };

  // Function to fetch the configs and topics for non-telematics devices
  const fetchConfigsAndTopics = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorName: selectedConnectorName,
        type: selectedConnectorType,
      });

      let fetchConfigResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConnectorConfigs",
        stringifiedData,
      );
      setContent(fetchConfigResponse.data.data.templateContent);
      setConfigs(
        fetchConfigResponse.data.data.configJson.config.map((obj) => ({
          value: null,
          ...obj,
        })),
      );

      setPreviewData((prev) => ({
        ...prev,
        connectionType: selectedConnectorType,
        category: selectedConnectorCategory,
        name: selectedConnectorName,
        description: selectedConnectorDescription,
        interfaces: [],
      }));

      let allTopics = await fetchAllTopics();

      setAllTopics(allTopics.data.topics);

      if (fetchConfigResponse.data.data.configJson.testConnection === true) {
        setTestConnectionAppicable(true);
      }
    } catch (err) {
      console.log("fetchConfigsAndTopics", err);
      showMessage("Unable to fetch the configs for the selected connector");
      setConfigs([]);
    }
  };

  const fetchCustomConnectorConfigs = async () => {
    try {
      await fetchCustomConnectorUserConfigs(
        selectedConnectorId,
        selectedConnectorVersion,
      );

      setPreviewData((prev) => ({
        ...prev,
        connectionType: selectedConnectorType,
        category: selectedConnectorCategory,
        name: selectedConnectorName,
        description: selectedConnectorDescription,
        interfaces: [],
      }));

      let allTopics = await fetchAllTopics();

      setAllTopics(allTopics.data.topics);

      const getAllCustomConnectorVersionsResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getAllCustomConnectorVersions",
        JSON.stringify({
          customConnectorId: selectedConnectorId,
        }),
      );

      setConnectorVersionList(
        getAllCustomConnectorVersionsResponse.data.data.versions,
      );
    } catch (err) {
      console.log("fetchConfigsAndTopics", err);
      showMessage("Unable to fetch the configs for the selected connector");
      setConfigs([]);
      setConnectorVersionList([]);
    }
  };

  useEffect(() => {
    if (selectedConnectorCategory === "Telematics Device") {
      fetchDevicesforManfacturer();
    } else if (selectedConnectorCategory === "Application") {
      fetchCustomConnectorConfigs();
    } else {
      fetchConfigsAndTopics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedConnectorVersion && selectedConnectorId) {
      fetchCustomConnectorUserConfigs(
        selectedConnectorId,
        selectedConnectorVersion,
      );
    }
  }, [
    selectedConnectorVersion,
    selectedConnectorId,
    fetchCustomConnectorUserConfigs,
  ]);

  return (
    <>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className="ConnectorConfigPageColumn"
        >
          <ConfigForm
            deviceData={deviceData}
            configs={configs}
            setConfigs={setConfigs}
            allTopics={allTopics}
            setAllTopics={setAllTopics}
            setPreviewData={setPreviewData}
            connectorVersionList={connectorVersionList}
            setSelectedConnectorVersion={setSelectedConnectorVersion}
            selectedConnectorVersion={selectedConnectorVersion}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          className="ConnectorConfigPageColumn addFlex"
        >
          <MoreInfoPart content={content} />
          <PreviewContainer>
            <PreviewTitle>Preview</PreviewTitle>
            <PreviewCard>
              <ReactFlowNodeCard data={previewData} />
            </PreviewCard>
            {/* <ReactflowForConfigCard data={previewData} /> */}
          </PreviewContainer>
        </Col>
      </Row>
      <ConnectorConfigBottomButtonContainer>
        {/* <StyledButton onClick={() => goBackClickHandler()}>
          Go Back
        </StyledButton> */}
        <StyledButtonsecondary
          onClick={() => goBackClickHandler()}
          style={{ width: "204px" }}
        >
          Go Back
        </StyledButtonsecondary>
        {/* <StyledButton
          style={{ backgroundColor: "#41BAC3", marginLeft: "20px" }}
          disabled={
            (!configs.length && !deviceData.length) || isConfigureProcessing
          }
          onClick={() => handleNextClick()}
        >
          {getButtonName(currentStep, isTestConnectionApplicable)}
        </StyledButton> */}
        <StyledButtonPrimary
          style={{ width: "204px", marginLeft: "20px" }}
          disabled={
            (!configs.length && !deviceData.length) ||
            isConfigureProcessing ||
            (selectedConnectorType === "Custom Connector" &&
              !selectedConnectorVersion.length)
          }
          onClick={() => handleNextClick()}
        >
          {getButtonName(currentStep, isTestConnectionApplicable)}
        </StyledButtonPrimary>
      </ConnectorConfigBottomButtonContainer>
      <GenericModal
        onClose={() => handleModalClose()}
        show={isModalOpen}
        title={modalTitleText}
      >
        <ModalDescription>
          {" "}
          {modalTitleText === "Configuration Successful!" ||
          "Deployment Successful!"
            ? ` You have successfully configured your connector. Please click continue.`
            : `Oops your deployment has failed, Try again.`}
        </ModalDescription>
        <ModalButtonContainer>
          {/* <StyledButton
            onClick={() => handleModalClose()}
            disabled={modalTitleText === "Deployment Successful!"}
          >
            Cancel
          </StyledButton> */}
          <StyledButtonsecondary
            onClick={() => handleModalClose()}
            disabled={modalTitleText === "Deployment Successful!"}
            style={{ width: "204px" }}
          >
            Cancel
          </StyledButtonsecondary>
          {/* <StyledButton
            style={{
              backgroundColor:
                modalTitleText === "Connection Successful!" ||
                modalTitleText === "Configuration Successful!" ||
                modalTitleText === "Deployment Successful!"
                  ? "#47CCD6"
                  : "#EA3A3A",
            }}
            onClick={() => handleModalButton()}
          >
            {modalTitleText === "Connection Successful!" ||
            modalTitleText === "Configuration Successful!" ||
            modalTitleText === "Deployment Successful!"
              ? "Continue"
              : "Try Again"}
          </StyledButton> */}
          <StyledButtonPrimary
            style={{
              width: "204px",
              // backgroundColor:
              //   modalTitleText === "Connection Successful!" ||
              //   modalTitleText === "Configuration Successful!" ||
              //   modalTitleText === "Deployment Successful!"
              //     ? "#47CCD6"
              //     : "#EA3A3A",
            }}
            onClick={() => handleModalButton()}
          >
            {modalTitleText === "Connection Successful!" ||
            modalTitleText === "Configuration Successful!" ||
            modalTitleText === "Deployment Successful!"
              ? "Continue"
              : "Try Again"}
          </StyledButtonPrimary>
        </ModalButtonContainer>
      </GenericModal>
    </>
  );
};

export default ConnectorsConfigurationPage;
